@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
html {
    zoom: 0.9;
} 
*/

.fr-view * img {
    @apply drop-shadow-2xl rounded;
    /* @apply md:object-fill object-cover; */
    @apply !w-full !max-w-full !h-auto;
}

.swal2-popup.swal2-modal.swal2-show {
    @apply md:w-1/2 w-full;
}

#rc-anchor-container {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

.rc-anchor-pt,
.rc-anchor-pt-text {
    display: none !important;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee {
    display: flex;
    overflow: hidden;
    position: relative;
}

.marquee-content {
    display: flex;
    white-space: nowrap;
    animation: marquee 40s linear infinite;
}